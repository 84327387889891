/**
 * Home
 * 
 * Web only version of home.js
 * dynamically loads three js loader libraries
 */

let THREE;
let vendor;

class HomeHeroProduct {

	constructor() { }

	async setup() {
		console.log('HomeHeroProduct::setup');

		const container = document.getElementById('home-hero-product'); // our container for the 3d scene
		if (!container) return;

		const isDesktop = (window.innerWidth >= 782) ? true : false;
		if (!isDesktop) {
			container.style.visibility = 'visible';
			return;
		}

		THREE = await import(/* webpackChunkName: "three-js" */ 'three');

		vendor = await import(/* webpackChunkName: "three-js-loaders" */ './modules/home-hero-vendor');

		this.mouse = { x: 0, y: 0 };
		this.target = { x: 0, y: 0 };
		this.windowHalfX = window.innerWidth / 2;
		this.windowHalfY = window.innerHeight / 2;

		document.addEventListener('mousemove', this.onMouseMove.bind(this), false);

		const containerSize = { width: container.clientWidth, height: container.clientHeight };

		// setup THREE.js
		const clock = new THREE.Clock()
		const scene = new THREE.Scene();

		// create and position the camera
		const camera = new THREE.PerspectiveCamera(50, containerSize.width / containerSize.height, 0.1, 200);

		// setup renderer
		const renderer = new THREE.WebGLRenderer({
			alpha: true,
			antialias: true,
		});
		renderer.outputColorSpace = THREE.SRGBColorSpace;
		renderer.setSize(containerSize.width, containerSize.height);


		// load environment lighting
		var envMap;

		var envPath = GAB.themeUrl + '/public/images/autoshop_01_4k.hdr';

		var pmremGenerator = new THREE.PMREMGenerator(renderer);
		pmremGenerator.compileEquirectangularShader();

		new vendor.RGBELoader()
			.setDataType(THREE.HalfFloatType)
			.load(envPath, (texture) => {
				envMap = pmremGenerator.fromEquirectangular(texture).texture;
				pmremGenerator.dispose();
				scene.environment = envMap;
				//scene.background = envMap;
			});

		//const controls = new FlyControls( camera, renderer.domElement );
		const loader = new vendor.GLTFLoader();

		// clear the container and add the THREEJS renderer
		container.innerHTML = "";
		container.appendChild(renderer.domElement);
		container.style.visibility = 'visible';

		this.renderer = renderer;
		this.scene = scene;
		this.camera = camera;
		this.clock = clock;
		this.model = null;

		// loader.load( GAB.themeUrl + '/public/3d-models/OpenPocketThreeJs_20230925_3.gltf', function ( gltf ) {
		// 	_self.model = gltf;
		// 	scene.add( gltf.scene );
		// 	_self.fitCameraToObject(camera, gltf.scene);
		// 	// start off the rendering/animation
		// 	_self.animate();
		// }, undefined, function ( error ) {
		// 	console.error( error );
		// });

		loader.load(GAB.themeUrl + '/public/3d-models/OpenPocket.gltf', this.onMeshLoaded.bind(this));

		this.animate();
	}

	async onMeshLoaded(gltf) {
		this.model = gltf.scene;
		this.scene.add(this.model);
		this.fitCameraToObject(this.camera, this.model);
	}

	/**
	 * Fit Camera to Object
	 * Based on code in this post:
	 * https://discourse.threejs.org/t/camera-zoom-to-fit-object/936
	 */
	fitCameraToObject(camera, obj, offset, controls) {
		offset = offset || 1.15;

		const boundingBox = new THREE.Box3();

		// get bounding box of object - this will be used to setup controls and camera
		boundingBox.setFromObject(obj);

		const center = boundingBox.getCenter(new THREE.Vector3());
		const size = boundingBox.getSize(new THREE.Vector3());

		// get the max side of the bounding box (fits to width OR height as needed )
		const maxDim = Math.max(size.x, size.y, size.z);
		const fov = camera.fov * (Math.PI / 180);
		let cameraZ = Math.abs(maxDim / 4 * Math.tan(fov * 2));

		cameraZ *= offset; // zoom out a little so that objects don't fill the screen

		camera.position.z = cameraZ;

		const minZ = boundingBox.min.z;
		const cameraToFarEdge = (minZ < 0) ? -minZ + cameraZ : cameraZ - minZ;

		camera.far = cameraToFarEdge * 3;
		camera.updateProjectionMatrix();

		if (controls) {
			// set camera to rotate around center of loaded object
			controls.target = center;

			// prevent camera from zooming out far enough to create far plane cutoff
			controls.maxDistance = cameraToFarEdge * 2;
			controls.saveState();

		} else {
			camera.lookAt(center)
		}
	}


	mapRange(value, low1, high1, low2, high2) {
		return low2 + (high2 - low2) * (value - low1) / (high1 - low1);
	}

	animate(test, hello) {
		requestAnimationFrame(this.animate.bind(this));

		//var delta = this.clock.getDelta();

		// this.camera.position.x += ( this.mouse.x - this.camera.position.x ) * 0.00005;
		// this.camera.position.y += ( - this.mouse.y - this.camera.position.y ) * 0.00005;
		// this.camera.lookAt( this.scene.position );

		//this.target.x = this.mouse.x * 0.001;
		//this.target.y = this.mouse.y * 0.001;

		// this.target.x = Math.min( Math.max( this.mouse.x, -650 ), 0 ) * 0.001;
		// this.target.y = Math.min( Math.max( this.mouse.y, 0 ), 650 ) * 0.001;

		this.target.x = this.mapRange(this.mouse.x, -this.windowHalfX, this.windowHalfX, -.650, 0);
		this.target.y = this.mapRange(this.mouse.y, -this.windowHalfY, this.windowHalfY, -.100, .250);

		if (this.model) {
			this.model.rotation.y += 0.05 * (this.target.x - this.model.rotation.y);
			this.model.rotation.x += 0.05 * (this.target.y - this.model.rotation.x);
		}

		this.renderer.render(this.scene, this.camera);
	}

	onMouseMove(e) {
		this.mouse.x = (e.clientX - this.windowHalfX); //e.clientX;
		this.mouse.y = (e.clientY - this.windowHalfY); //e.clientY;
		//console.log(this.mouse.x, this.mouse.y);
	}
}

const homeHeroProduct = new HomeHeroProduct();

window.addEventListener('load', function () {

	// setup home hero product (3D)
	homeHeroProduct.setup();
});